<template>
  <div class="bio_link_main_view">

    <div class="view_inner d-flex flex-column">

      <div class="view_top d-flex align-items-center">
        <h4 class="title">Video Block</h4>
        <div class="view-right d-flex align-items-center ml-auto">
          <bio-shortened-url></bio-shortened-url>
          <router-link class="cross_icon ml-auto" :to="{name: 'bio_links'}">
            <i class="fal fa-times"></i>
          </router-link>
        </div>
      </div>

      <div class="view_center">
        <div class="steps_dot mb-3">
          <router-link :to="{name: 'select_content'}"
                       class="btn---cta light-blue btn-round">
            <span>Go Back</span>
          </router-link>
          <div class="circles d-inline-block text-center">
            <span class="dotted_circle active"></span>
          </div>


          <button @click.prevent="validateAndStoreBlock()" class="btn---cta btn-blue btn-round">
                        <span>
                            <template v-if="!getBioLinkVideoSectionAdd._id">
                                Save Block
                            </template>
                            <template v-else>
                                Update Block
                            </template>
                        </span>
          </button>
        </div>

        <div class="input-block-list">
          <div class="block-heading">
            <h2>🎬 Video</h2>
            <p>Add video link to your profile with the Video Block. Use this block to add engaging video content.</p>
          </div>

          <!-- add links section -->
          <div class="add-block-list">
            <div class="add-block-item">
              <div class="block-inner">
                <div
                  class="input_icon_text mb-3 p-2 row w-100 m-auto d-flex justify-content-around align-items-center profile__box">
                  <img :width="item.width" v-if="item.key !== 'fb'" class="col-lg-1 ml-2" v-for="item in videoRegexArray" v-tooltip="item.name"
                       :src="item.image"/>
                </div>
                <!-- Music player link -->
                <div
                  :class="{'input-field-error': videoValidation.url || videoValidation.urlValid || videoValidation.urlLength || videoValidation.isValidVideo}"
                  class="input_icon_text mt-3 d-flex align-items-center profile__box">
                  <div class="content">
                    <div class="url">
                      <div class="avatar mr-1">
                        <i class="fal fa-link"></i>
                      </div>
                      Add a supported Music Player link
                    </div>
                    <div class="name">
                      <div class="validation_input d-flex">
                        <img class="p-1" v-if="videoObj" :width="videoObj.width" :src="videoObj.image"/>
                        <url
                          class="ml-2"
                          v-model="getBioLinkVideoSectionAdd.url"
                          :validations="videoValidation"
                          v-bind:blur="() => getBioLinkVideoSectionAdd.url = focusoutConcatenation(getBioLinkVideoSectionAdd.url)"
                          placeholder="e.g. https://www.youtube.com/embed/V7huTUjU3YU"></url>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="input-error" v-if="videoValidation.url">{{ URLMessages.url }}</span>
                <span class="input-error" v-else-if="videoValidation.urlLength">{{ URLMessages.urlLength }}</span>
                <span class="input-error" v-else-if="videoValidation.urlValid">{{ URLMessages.urlValid }}</span>
                <span class="input-error"
                      v-else-if="videoValidation.isValidVideo">Add a supported Video Player link</span>
                <!-- Title input -->
                <div :class="{'input-field-error': titleLength}" class="mt-3 input_icon_text d-flex align-items-center">
                  <div class="content">
                    <div class="d-flex url">
                      <div class="avatar mr-1">
                        <i class="fal fa-align-left"></i>
                      </div>
                      Title
                    </div>
                    <div class="name">
                      <div class="validation_input">
                        <input type="text" placeholder="Add a Title..." v-model="getBioLinkVideoSectionAdd.title"
                               data-cy="link-title"/>
                      </div>
                    </div>
                  </div>
                </div>
                <span class="input-error" v-if="titleLength">Title should not be greater than 150 characters</span>
                <!-- height component -->
                <height-component
                  @heightObj="heightObj($event)"
                  :height_enabled="getBioLinkVideoSectionAdd.height_enabled"
                  :height="getBioLinkVideoSectionAdd.height"
                  :heightAllowed="heightAllowed"
                ></height-component>

                <!-- Schedule component -->
                <schedule-component
                  ref="schedule"
                  :enabled="getBioLinkVideoSectionAdd.schedule_enabled"
                  :data="getBioLinkVideoSectionAdd.schedule"
                ></schedule-component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import BioShortenedURL from './BioShortenedURL'

import {
  URLMessages,
  videoValidation,
  videoRegexArray
} from '../../../../../common/attributes'

export default ({
  data () {
    return {
      videoValidation: JSON.parse(JSON.stringify(videoValidation)),
      URLMessages: URLMessages,
      titleLength: false,
      videoRegexArray: videoRegexArray,
      videoObj: {
        name: '',
        image: '',
        width: ''
      },
      heightAllowed: {
        error: '',
        allowed: false
      }
    }
  },
  created () {
    this.isBioLinkFirstStepVerified()
    let logo = this.videoRegexArray.find(item => this.getBioLinkVideoSectionAdd.url && this.getBioLinkVideoSectionAdd.url.match(item.regex))
    this.videoObj = logo ? logo : {}
    this.getBioLinkVideoSectionAdd.embed_url = this.videoObj && this.videoObj.key ? this.computeEmbedURL(this.videoObj.key.toLowerCase()) : ''
  },
  components: {
    'bio-shortened-url': BioShortenedURL,
    'url': () => import('@/ui/URL'),
    'height-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/HeightComponent'),
    'schedule-component': () => import('@/views/pages/bio-link/widgets/snippets/bio-common-components/ScheduleComponent')
  },
  computed: {
    ...mapGetters([
      'getBioLinkVideoSectionAdd',
      'getProfile'
    ])
  },
  methods: {
    ...mapActions(['storeBioContentSection']),
    async validateAndStoreBlock () {
      this.videoValidation.url = true
      this.videoValidation.isValidVideo = false
      if (this.getBioLinkVideoSectionAdd.url && this.getBioLinkVideoSectionAdd.url.length > 8) {
        this.videoValidation.url = false
        this.getBioLinkVideoSectionAdd.url = this.urlConcatenation(this.getBioLinkVideoSectionAdd.url)
        this.videoValidation.urlLength = !this.maxLength(this.getBioLinkVideoSectionAdd.url, 500)
        this.videoValidation.urlValid = !this.isValidURL(this.getBioLinkVideoSectionAdd.url)
      }

      let urlCheck = Object.keys(this.videoValidation).every(k => this.videoValidation[k] === false)
      if (!urlCheck) {
        await this.$store.dispatch('toastNotification', {message: 'Url validation failed.', type: 'error'})
        return
      }

      this.videoValidation.isValidVideo = !(!!this.videoRegexArray.find(item => this.getBioLinkVideoSectionAdd.url.match(item.regex)))
      if (this.videoValidation.isValidVideo) {
        await this.$store.dispatch('toastNotification', {
          message: 'Add a Supported Video Music Player link.',
          type: 'error'
        })
        return
      }

      this.titleLength = this.getBioLinkVideoSectionAdd.title && !this.maxLength(this.getBioLinkVideoSectionAdd.title, 150)
      if (this.titleLength) {
        await this.$store.dispatch('toastNotification', {message: 'Title validation failed.', type: 'error'})
        return
      }

      const scheduleComponentRef = this.$refs.schedule
      const proceed = await scheduleComponentRef.checkScheduleSubmission()
      if (!proceed) {
        return
      }

      this.getBioLinkVideoSectionAdd.schedule_enabled = scheduleComponentRef.schedule_enabled
      if (scheduleComponentRef.schedule_enabled) {
        this.getBioLinkVideoSectionAdd.schedule = scheduleComponentRef.schedule
      }

      let res = await this.storeBioContentSection('getBioLinkVideoSectionAdd')
      if (res && res.data.status) {
        this.$router.push({name: 'select_content'})
      }

      if(res && !res.data.status) {
        this.alertMessage(res.data.message, 'error')
      }

    },
    heightObj (event) {
      this.getBioLinkVideoSectionAdd.height_enabled = event.height_enabled
      this.getBioLinkVideoSectionAdd.height = event.height
    },

    /**
     * computing the Embed URL
     * @param key
     * @returns {string|*}
     */
    computeEmbedURL (key = '') {
      let url = new URL(this.getBioLinkVideoSectionAdd.url)
      console.log(url)
      this.heightAllowed.allowed = false
      this.heightAllowed.error = ''

      if (key === 'youtube' && url.host === 'youtu.be') {
        return `https://youtube.com/embed/${url.pathname.substring(1)}`
      }

      if (key === 'youtube' && url.pathname && url.pathname.includes('watch')) {
        const split = url.search ? (url.search.includes('list') ? url.search.substring(3).split('&')[0] : url.search.substring(3)) : url.search.substring(3)
        return `https://youtube.com/embed/${split}`
      }

      if (key === 'youtube' && url.pathname && url.pathname.includes('embed')) {
        return this.getBioLinkVideoSectionAdd.url
      }

      if (key === 'youtube' && url.pathname && url.pathname.includes('shorts')) {
        return `https://youtube.com/embed/${url.pathname ? url.pathname.split('/')[2] : ''}`
      }

      if (key === 'vimeo' && url.origin && url.origin.includes('https://vimeo.com')) {
        return `https://player.vimeo.com/video/${url.pathname ? url.pathname.substring(1) : ''}`
      }

      if (key === 'vimeo' && url.origin && url.origin.includes('https://player.vimeo.com')) {
        return this.getBioLinkVideoSectionAdd.url
      }

      if (key === 'twitch' && url.pathname && url.pathname.includes('videos')) {
        return `https://player.twitch.tv/?video=${url.pathname ? url.pathname.split('/')[2] : ''}`
      }

      if (key === 'twitch' && url.host && url.host.includes('twitch')) {
        return `https://player.twitch.tv/?video=${url.pathname ? url.pathname.substring(1) : ''}`
      }

      if (key === 'tiktok' && url.host && url.host.includes('tiktok')) {
        return `https://www.tiktok.com/embed/v2/${url.pathname ? url.pathname.split('/')[3] : ''}`
      }

      if (key === 'dailymotion' && url.pathname && url.pathname.includes('embed')) {
        return this.getBioLinkVideoSectionAdd.url
      }

      if (key === 'dailymotion' && url.host && url.host.includes('dailymotion')) {
        console.log('dailymotion')
        return `https://www.dailymotion.com/embed/video/${url.pathname ? url.pathname.split('/')[2] : ''}`
      }

      if (['facebook', 'fb'].includes(key)) {
        return this.getBioLinkVideoSectionAdd.url
      }

      if (['instagram'].includes(key)) {
        this.heightAllowed.allowed = true
        this.heightAllowed.error = 'Custom Height is not adjustable in case of Instagram'
        return this.getBioLinkVideoSectionAdd.url
      }

      return this.getBioLinkVideoSectionAdd.url
    }
  },
  watch: {
    'getBioLinkVideoSectionAdd.title' (value) {
      this.titleLength = (value && value.length) ? !this.maxLength(value, 150) : false
    },
    'getBioLinkVideoSectionAdd.url' (value) {
      if (!this.getBioLinkVideoSectionAdd.url) {
        this.videoValidation.isValidVideo = false
        this.getBioLinkVideoSectionAdd.embed_url = ''
        return
      }
      let logo = this.videoRegexArray.find(item => this.getBioLinkVideoSectionAdd.url.match(item.regex))
      this.videoObj = logo ? logo : {}
      this.getBioLinkVideoSectionAdd.embed_url = this.videoObj && this.videoObj.key ? this.computeEmbedURL(this.videoObj.key.toLowerCase()) : ''
      this.videoValidation.isValidVideo = false
      if (value && value.length) {
        this.videoValidation.isValidVideo = !(!!this.videoRegexArray.find(item => this.getBioLinkVideoSectionAdd.url.match(item.regex)))
      }
    }
  }

})
</script>
